import * as React from 'react';
import { Button } from 'antd';
import { ExternalLink } from 'lucide-react';
import { getLangKey } from 'models/Language';
import Hint from 'components/Hint';
import { onboardingCreateExampleTask } from 'api/ProjectApi';
import styles from './index.module.css';
import cx from 'classnames';

import track, { ONBOARDING_USE_EXAMPLE_DATA } from 'utils/analytics';

import * as translations from './strings';

type Props = {
  projectUrl: string;
  projectId: number;
  openProject?: (url: string, mode: string) => void;
  experiments: string[];
};

const TryBugHerd: React.FC<Props> = ({
  projectUrl,
  projectId,
  openProject = window.open,
  experiments
}) => {
  const strings = translations[getLangKey()];

  return (
    <div className={cx(styles.tryOuter, styles.stepContainer)}>
      <div className={styles.tryInner}>
        <div>{strings.tryBugHerdDescription}</div>
        <div>BugHerd will appear after your page has finished loading.</div>
        <Button
          type="primary"
          onClick={() => openProject(projectUrl, '_blank')}
          className={styles.visitWebsiteButton}
        >
          <i className="anticon">
            <ExternalLink size={14} />
          </i>
          {strings.visitWebsite}
        </Button>
        {experiments.includes('onboarding_v4_skip_task_create') && (
          <a
            type="link"
            onClick={() => {
              track(ONBOARDING_USE_EXAMPLE_DATA);
              onboardingCreateExampleTask(projectId);
            }}
          >
            Or, see some example feedback we've prepared earlier.
          </a>
        )}
      </div>
      <Hint
        message="Need help? We can setup your project"
        description={
          <div>
            <p>
              Just click the button below and fill out the details, we'll take
              care of the rest.
            </p>
            <Button
              type="primary"
              onClick={() =>
                window.open(
                  'https://bugherd.typeform.com/to/wIfihfHy',
                  '_blank'
                )
              }
              className={styles.visitWebsiteButton}
            >
              Get the free Concierge service
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default TryBugHerd;
