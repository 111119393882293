import React, {
  FC,
  MouseEvent,
  MutableRefObject,
  useState,
  useRef,
  useEffect
} from 'react';
import { Tag, Tooltip, Button } from 'antd';
import { Paperclip as Attach, Maximize, Play } from 'lucide-react';
import { FileType, getFileType, MS_DOC_FILE_TYPES } from './getFileType';
import styles from './styles.module.css';
import cx from 'classnames';
import getThumbnail from '../../../CreateTask/create_task/getThumbnail';
import Loader from '../../../../components/Loader';

type Props = {
  id?: number;
  url?: string;
  name: string;
  deleteAttachment: (id: number) => void;
  setPreviewSource: (url: string | null) => void;
  onClick?: (event: MouseEvent) => void;
  container: HTMLDivElement;
  isAdminView?: boolean;
};

export const AttachmentTag: FC<Props> = props => {
  const {
    url,
    setPreviewSource,
    container,
    id,
    name,
    isAdminView,
    deleteAttachment
  } = props;
  const extension = url?.split('.').pop();
  const fileType = getFileType(extension || '');
  const [loadingPreview, setLoadingPreview] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [thumbnailInitialised, setThumbnailInitialised] = useState<boolean>(
    false
  );
  const imgContainerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const isImage = fileType === FileType.Image;
  const isVideo = fileType === FileType.Video;
  const isJson = extension === 'json';
  const isCsv = extension === 'csv';
  const isTxt = extension === 'txt';
  const isMSDoc = MS_DOC_FILE_TYPES.includes(extension || '');

  const hasOnClick = isImage || isVideo || isJson || isCsv || isMSDoc || isTxt;

  const handleTagClick = hasOnClick
    ? event => {
        event.preventDefault();
        event.stopPropagation();
        setPreviewSource(url || '');
      }
    : undefined;

  const onLoaded = () => setLoadingPreview(false);

  const initVideoThumbnail = async () => {
    if (!url) return;
    const response = await fetch(url);
    const blob = await response.blob();
    if (blob) getThumbnail(blob, imgContainerRef.current, onLoaded);
  };

  const handlePreviewClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (isOpen) setIsOpen(false);
    setPreviewSource(url || '');
  };

  useEffect(() => {
    if (isVideo && isOpen && !thumbnailInitialised) {
      initVideoThumbnail();
      setThumbnailInitialised(true);
    }
  }, [isOpen]);

  return (
    <Tooltip
      className={styles.tooltip}
      overlayClassName={styles.tooltipOverlay}
      trigger={['hover']}
      open={isOpen}
      placement="topRight"
      onOpenChange={open => setIsOpen((isImage || isVideo) && open)}
      // @ts-expect-error
      getPopupContainer={() =>
        isAdminView
          ? document.getElementById('project_board_container')
          : container
      }
      title={
        <div
          ref={imgContainerRef}
          className={cx(styles.tooltipPreview, {
            [styles.videoPreview]: isVideo
          })}
          onClick={handlePreviewClick}
        >
          {isImage && (
            <img
              className={styles.hoverPreviewImage}
              src={url}
              alt="hover_preview"
              onLoad={onLoaded}
            />
          )}
          <div className={styles.overlay} onClick={handlePreviewClick}>
            {loadingPreview || isVideo ? (
              <div className={styles.playCircle}>
                <div className={styles.innerCircle}>
                  {loadingPreview ? (
                    <Loader
                      useDarkStyles
                      className={styles.previewLoaderIcon}
                    />
                  ) : (
                    <Play className={styles.playIcon} />
                  )}
                </div>
              </div>
            ) : (
              <Button icon={<Maximize />} type="primary" size="small">
                Open
              </Button>
            )}
          </div>
        </div>
      }
    >
      <Tag
        className={styles.tag}
        closable
        // @ts-expect-error
        onClose={() => deleteAttachment(id)}
      >
        <div className={styles.tagContent}>
          <Attach className={styles.attachIcon} />
          <a
            href={url}
            rel="noreferrer"
            target="_blank"
            onClick={handleTagClick}
            className={styles.previewLink}
          >
            <span className={styles.filename} title={name}>
              {name}
            </span>
          </a>
        </div>
      </Tag>
    </Tooltip>
  );
};
