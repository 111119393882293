import React from 'react';
import { Space, Alert, Button } from 'antd';

import * as translations from './strings';
import { getLangKey } from 'models/Language';
import styles from './styles.module.css';
import { AdditionalMemberState } from './useCounterState';

import { getUpgradePlan } from './getUpgradePlan';
import { Plan, PlanInterval } from '../types';
import { migratePlanUrl } from '../routes';
import AdditionalMemberPrice from '../AdditionalMemberPrice';

type Props = {
  organizationId: string;
  newMemberCount: number;
  currentMemberCount: number;
  subscriptionState?: AdditionalMemberState;
  currentPlan: Plan;
};
const getPlanPriceWithAdditionalMembers = ({
  currentPlanPrice,
  currentPlanInterval,
  memberCount
}: {
  currentPlanPrice: number;
  currentPlanInterval: PlanInterval;
  memberCount: number;
}) =>
  currentPlanPrice + AdditionalMemberPrice[currentPlanInterval] * memberCount;

const Alerts: React.FC<Props> = ({
  organizationId,
  subscriptionState,
  newMemberCount,
  currentMemberCount,
  currentPlan
}) => {
  const strings = translations[getLangKey()];
  const upgradePlan = getUpgradePlan({
    organizationId,
    name: currentPlan.name
  });
  return (
    <div className={styles.messagingContainer}>
      {subscriptionState === 'seatsOccupied' && (
        <Alert
          type="warning"
          message={
            <p className={styles.messageTitle}>{strings.seatsOccupiedTitle}</p>
          }
          description={
            <span>
              {strings.removeUsers}{' '}
              <Button
                className={styles.upgradeTo}
                type="link"
                onClick={() =>
                  (window.location.href = `/settings/organizations/${organizationId}/team`)
                }
              >
                {strings.teamsPage}
              </Button>{' '}
              {strings.page}
            </span>
          }
          showIcon
        />
      )}
      {subscriptionState === 'upgrade' && (
        <Alert
          type="info"
          message={
            <p className={styles.messageTitle}>
              {strings.upgrageMessageTitle(upgradePlan?.name || 'null')}
            </p>
          }
          description={
            <span>
              {strings.upgrageMessage({
                upgradePlan: upgradePlan?.name || 'null',
                upgradePlanPrice: upgradePlan?.price || 0,
                currentPlan: currentPlan.name,
                additionalMembers: newMemberCount,
                currentPlanAddPrice: getPlanPriceWithAdditionalMembers({
                  currentPlanPrice: currentPlan?.price,
                  currentPlanInterval: currentPlan?.interval,
                  memberCount: newMemberCount
                }),
                upgradePlanSeats: upgradePlan?.maxMembers || 0,
                planInterval:
                  currentPlan?.interval === 'Monthly'
                    ? strings.mo
                    : strings.year
              })}{' '}
              <Button
                type="link"
                className={styles.upgradeTo}
                href={migratePlanUrl({
                  organizationId,
                  planId: upgradePlan?.id || '',
                  additionalMembers: newMemberCount
                })}
              >
                {strings.upgradeTo(upgradePlan?.name || 'null')}
              </Button>
            </span>
          }
          showIcon
        />
      )}
      {subscriptionState === 'canAdd' && (
        <div className={styles.confirmMessage}>
          <Space direction={'horizontal'} />
          <p>
            {strings.confirmMessage({
              count: newMemberCount - currentMemberCount,
              currentPlanName: currentPlan.name
            })}
          </p>
          <Space direction={'horizontal'} />
          <p>{strings.confirmMessage1}</p>
        </div>
      )}
      {subscriptionState === 'canRemove' && (
        <div className={styles.confirmMessage}>
          <Space direction={'horizontal'} />
          <p>
            {strings.confirmRemoveMessage({
              count: currentMemberCount - newMemberCount,
              currentPlanName: currentPlan.name
            })}
          </p>
          <Space direction={'horizontal'} />
          <p>{strings.confirmRemoveMessage1}</p>
        </div>
      )}
    </div>
  );
};

export default Alerts;
