import React, { FC, useState, useEffect } from 'react';
import { put } from 'appJS/utils/fetch';
import styles from './index.module.css';
import { message, Input } from 'antd';

type Props = {
  name: string;
  fileType: string;
  assetEndpoint: string;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  canManageAssets: boolean;
};

const AssetName: FC<Props> = ({
  name,
  fileType,
  assetEndpoint,
  isEditing,
  setIsEditing,
  canManageAssets
}) => {
  const [fileName, setFileName] = useState<string>(name);

  useEffect(() => {
    setFileName(name);
  }, [name]);

  const handleEscapePress = event => {
    if (event.code === 'Escape') {
      setFileName(name);
      setIsEditing(false);
    }
  };

  const handleOnChange = event => {
    setFileName(event.target.value);
  };

  const handleUpdate = async () => {
    if (isEditing) setIsEditing(false);
    if (fileName !== name) {
      const response = await put(assetEndpoint, {
        file_name: `${fileName}.${fileType}`
      });
      if (response) message.success('Your asset has been updated');
    }
  };

  const handleNameClick = event => {
    event.stopPropagation();
    event.preventDefault();
    if (canManageAssets) setIsEditing(true);
  };

  return isEditing ? (
    <Input
      value={fileName}
      onChange={handleOnChange}
      maxLength={200}
      size="small"
      onPressEnter={handleUpdate}
      autoFocus
      onBlur={() => {
        handleUpdate();
        setIsEditing(false);
      }}
      onClick={e => e.preventDefault()}
      className={styles.fileNameInput}
      onKeyUp={handleEscapePress}
    />
  ) : (
    <h3 onClick={handleNameClick}>{name}</h3>
  );
};

export default AssetName;
