import React, { FC, useEffect, useState } from 'react';
import { Tabs, Dropdown, Button } from 'antd';
import { getTabItems, getMobileItems } from './items';
import Loader from 'components/loader';
import { ActiveKey, NewSettingsProps } from './types';
import attachListenerOnMultipleNodes from 'utils/domListeners';
import { Menu } from 'lucide-react';
import { ApolloProvider, useQuery } from '@apollo/client';
import client from 'api/apollo/client';
import { PROJECT_QUERY } from '../project_settings/queries';
import styles from './index.module.css';
import useMediaQuery from '../../../../clients/sidebar/views/CreateTask/create_task/useMediaQuery';
import { View } from '../project_board/project_nav/types';

export const NewProjectSettings: FC<NewSettingsProps> = props => {
  const {
    projectInit,
    integrations,
    bugherdUrl,
    user,
    bitBucketHook,
    gitHubHook,
    gitLabHook,
    accessTo: {
      guestsSeeGuestsAccess,
      guestsEditTaskStatusAllowed,
      mobileScreenshots
    },
    activeKey,
    setActiveKey,
    setView,
    view,
    showAssetsPage,
    authenticityToken
  } = props;

  const [hasParagonLoaded, setHasParagonLoaded] = useState<boolean>(false);

  const { loading, data, refetch } = useQuery(PROJECT_QUERY, {
    variables: { projectId: projectInit.id }
  });
  const isMobileView = useMediaQuery('(max-width: 992px)');

  useEffect(() => {
    attachListenerOnMultipleNodes(
      "[data-trigger='project_settings']",
      (element: Element) =>
        element.addEventListener('click', () => {
          setView(View.Settings);
          setActiveKey(ActiveKey.Sidebar);
        })
    );

    const MAX_TRIES = 50;
    let tries = 0;
    const checkParagonInterval = setInterval(() => {
      if (tries >= MAX_TRIES) return clearInterval(checkParagonInterval);
      // @ts-expect-error
      if (window.paragon) {
        clearInterval(checkParagonInterval);
        setHasParagonLoaded(true);
      }
      tries++;
    }, 200);

    return () => clearInterval(checkParagonInterval);
  }, []);

  const project = data?.currentUser?.userProject;

  const tabItems = getTabItems({
    project,
    accessTo: {
      guestsSeeGuestsAccess,
      guestsEditTaskStatusAllowed,
      mobileScreenshots
    },
    bugherdUrl,
    integrations,
    user,
    bitBucketHook,
    gitHubHook,
    gitLabHook,
    refetch,
    projectInit,
    hasParagonLoaded,
    authenticityToken,
    showAssetsPage
  });

  const handleMobileClick = key => {
    setActiveKey(key);
  };

  const items = getMobileItems(handleMobileClick);

  if (loading) return <Loader spinning={loading} />;

  if (!data)
    return <div>No data {console.error('No data for project settings')} </div>;

  const dropdownName = () => {
    let str = String(activeKey);
    str = str.replace(/_/g, ' '); // Replace underscores with spaces
    str = str.replace(/\//g, ' / '); // Add spaces around slashes
    str = str.replace(/\b\w/g, l => l.toUpperCase()); // Capitalize the first letter of each word

    return str;
  };

  const handleTabsOnChange = (key: ActiveKey) => {
    setActiveKey(key);
    const isIntegrationsOrTags =
      key === ActiveKey.Integrations || key === ActiveKey.Tags;
    // @ts-expect-error
    if (isIntegrationsOrTags && view !== key) setView(key);
  };

  return (
    <div className={styles.settingsWrapper}>
      {project && (
        <div>
          {isMobileView && (
            <Dropdown
              menu={{
                items
              }}
              placement="bottomLeft"
            >
              <Button
                type="default"
                className={styles.mobileDropdown}
                icon={<Menu />}
              >
                {dropdownName()}
              </Button>
            </Dropdown>
          )}

          <Tabs
            activeKey={activeKey}
            // @ts-expect-error
            onChange={handleTabsOnChange}
            tabPosition="left"
            items={tabItems}
            className={styles.tabsWrapper}
          />
        </div>
      )}
    </div>
  );
};

export default (props: NewSettingsProps) => (
  <ApolloProvider client={client}>
    <NewProjectSettings {...props} />
  </ApolloProvider>
);
