import React, { useState, useEffect } from 'react';
import { Button, Input, Divider } from 'antd';
import { FixedSizeList } from 'react-window';
import styles from './index.module.css';
import * as translations from '../strings';
import { getLangKey } from 'appJS/models/Language';
import cx from 'classnames';
import projectEmptyState from 'appJS/assets/images/projects-empty-state.png';

const { Search } = Input;
const strings = translations[getLangKey()];
const AMOUNT_OF_RECENT_PROJECTS_SHOW = 5;
const ITEM_HEIGHT = 30;
const LIST_WIDTH = 308;

interface Project {
  id: number;
  name: string;
}

interface ProjectsListProps {
  latestProjects: Project[];
  organizationUrl: string;
  organizationId: number;
  isGuest: boolean;
}

const ProjectsList: React.FC<ProjectsListProps> = ({
  latestProjects,
  organizationUrl,
  organizationId,
  isGuest
}) => {
  const recentProjects = latestProjects.slice(
    0,
    AMOUNT_OF_RECENT_PROJECTS_SHOW
  );
  const sortedProjects = [...latestProjects].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const [searchByName, setSearchByName] = useState<string>('');
  const [filteredProjects, setFilteredProjects] = useState<any[]>(
    sortedProjects
  );

  useEffect(() => {
    setFilteredProjects(
      sortedProjects.filter(project =>
        project.name.toLowerCase().includes(searchByName.toLowerCase())
      )
    );
  }, [searchByName]);

  const href = (projectId: number) => {
    return isGuest
      ? `/organizations/${organizationId}/projects/${projectId}`
      : `/projects/${projectId}/kanban`;
  };

  const handleSearchChange = (value: string) => {
    setSearchByName(value);
  };

  const calculateListHeight = items => {
    return Math.min(400, items.length * ITEM_HEIGHT);
  };

  const renderItem = ({ data, index, style }) => {
    const item = data[index];
    const { type } = item;
    if (type === 'header') {
      return (
        <h4 className={styles.listHeader} style={style}>
          {item.name}
        </h4>
      );
    }
    if (type === 'divider') {
      return (
        <Divider key={'divider'} className={styles.divider} style={style} />
      );
    }
    return (
      <a type="text" href={href(item.id)} className={styles.link} style={style}>
        <span className={styles.projectItem}>{item.name}</span>
      </a>
    );
  };

  const renderList = items => {
    if (items.length === 0) {
      return <p>{strings.noProjectsFound}</p>;
    }
    return (
      <div className={styles.projectList}>
        <FixedSizeList
          height={calculateListHeight(items)}
          itemData={items}
          itemCount={items.length}
          itemSize={ITEM_HEIGHT}
          width={LIST_WIDTH}
        >
          {renderItem}
        </FixedSizeList>
      </div>
    );
  };

  if (latestProjects.length === 0) {
    return (
      <div className={cx(styles.container, styles.emptyStateContainer)}>
        <img
          className={styles.emptyStateImage}
          src={projectEmptyState}
          alt="project_empty_state"
        />
        <span>{strings.emptyProjectsMessage}</span>
        <Button
          type="primary"
          href={`/organizations/${organizationId}/projects/new`}
        >
          {strings.createNewProject}
        </Button>
      </div>
    );
  }

  const showFilteredProjects = searchByName !== '';
  const showAllProjects =
    latestProjects.length > AMOUNT_OF_RECENT_PROJECTS_SHOW;

  const fullProjects = [
    { type: 'header', name: strings.recentProjects },
    ...recentProjects
  ];

  if (showAllProjects) {
    fullProjects.push(
      { type: 'divider', name: 'divider' },
      { type: 'header', name: strings.allProjects },
      ...sortedProjects
    );
  }

  return (
    <div className={cx(styles.container, styles.projectsContainer)}>
      <div className={styles.content}>
        <h4 className={styles.header}>{strings.myProjects}</h4>
        <Search
          placeholder={strings.search}
          defaultValue={searchByName}
          size="small"
          onChange={e => handleSearchChange(e.target.value)}
        />

        {showFilteredProjects
          ? renderList(filteredProjects)
          : renderList(fullProjects)}
      </div>
      <div className={styles.footer}>
        <a href={organizationUrl}>
          <h4 className={styles.browseProjectsButton}>
            {strings.browseProjects}
          </h4>
        </a>
      </div>
    </div>
  );
};

export default ProjectsList;
