import React, { FC } from 'react';
import { SortableProject } from 'models/project/sortUserFriendly';
import { Column } from '../../../../models/Project';
import MarkAs from './MarkAs';
import More from './More';
import styles from './styles.module.css';
import cx from 'classnames';
import camelcaseKeys from 'camelcase-keys';

type Props = {
  taskMoveToProject: boolean;
  taskDelete: boolean;
  taskEditStatus: boolean;
  container: HTMLDivElement;
  columns: Column[];
  column: Column;
  taskId: number;
  projectId: number;
  shareUrl: string;
  updateTask: (
    columnOrStatus:
      | Column
      | { statusId?: number | null; columnId: number | null }
  ) => any;
  isSmallDetailsView?: boolean;
  isAdminView?: boolean;
  projects: SortableProject[];
  projectsLoading: boolean;
  moveToProject: (projectId: number) => void;
  deleteTask: (
    onError?: (error: Error) => void,
    onSuccess?: () => void
  ) => void;
  isNewSidebar?: boolean;
};

const TaskActions: FC<Props> = ({
  taskMoveToProject,
  taskDelete,
  taskEditStatus,
  container,
  columns,
  column,
  taskId,
  projectId,
  shareUrl,
  updateTask,
  isSmallDetailsView,
  isAdminView,
  projects,
  projectsLoading,
  moveToProject,
  deleteTask,
  isNewSidebar
}) => {
  return (
    <div
      className={cx(styles.actionContainer, {
        [styles.adminContainer]: isAdminView
      })}
    >
      <MarkAs
        columns={columns}
        column={column}
        updateStatus={(
          columnOrStatus:
            | Column
            | { statusId: number | null; columnId: number | null }
        ) => updateTask(columnOrStatus)}
        container={container}
        taskEditStatus={taskEditStatus}
        isNewSidebar={isNewSidebar}
        isAdminView={isAdminView}
      />
      <div
        className={cx(styles.moreActionsContainer, {
          [styles.moreActionsSmallView]: isSmallDetailsView || !isAdminView
        })}
      >
        <More
          isAdminView={isAdminView}
          container={container}
          projectId={projectId}
          taskId={taskId}
          taskDelete={taskDelete}
          taskMoveToProject={taskMoveToProject}
          projects={camelcaseKeys(projects)}
          projectsLoading={projectsLoading}
          shareUrl={shareUrl}
          isSmallDetailsView={isSmallDetailsView}
          moveToProject={moveToProject}
          deleteTask={deleteTask}
          isNewSidebar={isNewSidebar}
        />
      </div>
    </div>
  );
};

export default TaskActions;
