export const en = {
  myProjects: 'My Projects',
  dashboard: 'Dashboard',
  team: 'Team',
  orgSettings: 'Settings',
  switchOrg: 'My Organizations',
  createProject: 'Create new project',
  editProfile: 'Edit your profile',
  needHelp: 'Need help?',
  goHome: 'Go to home page',
  logOut: 'Logout',
  startAccount: 'Start own account',
  newOrg: 'Create new organization',
  remaining: 'remaining!',
  upgradeNow: ' Upgrade now!',
  toAdmin: 'To admin',
  startYourOwn: (amountOfTrialDays: number) =>
    `This will start your own ${amountOfTrialDays} trial of BugHerd right now. Are you ready to proceed?`,
  createOrg:
    'This will create a new BugHerd organization subscription. Are you ready to proceed?',
  recentProjects: 'Recent:',
  allProjects: 'All Projects:',
  browseProjects: 'Browse all projects',
  currentOrg: 'Current Organization',
  search: 'Search',
  noProjectsFound: 'No projects found',
  emptyProjectsMessage: 'Begin by setting up your first project',
  createNewProject: 'Create new project'
};

export type Translations = typeof en;
