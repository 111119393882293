type IntegrationData = {
  label: string;
  connectedText?: string;
  viewIn?: string;
  linkText?: string;
  createText?: string;
  link?: (projectName: string) => string;
  helpLink?: string;
  helpId?: number;
};

type IntegrationsData = {
  [key: string]: IntegrationData;
};

const integrationsData: IntegrationsData = {
  generic: {
    label: 'Some Integration',
    connectedText: 'Task sent.',
    viewIn: 'View in Some Integration',
    linkText: 'Link existing task',
    createText: 'Add to Some Integration'
  },
  baseCamp: {
    label: 'Basecamp'
  },
  github: {
    label: 'GitHub',
    connectedText: 'Connected to:',
    link: (projectName: string) => `https://github.com/${projectName}`
  },
  slack: {
    label: 'Slack',
    connectedText: 'Connected to:',
    link: (projectName: string) =>
      `https://slack.com/channels/${projectName.replace('#', '')}`
  },
  trello: {
    label: 'Trello',
    connectedText: 'Task sent:',
    viewIn: 'View in Trello',
    createText: 'Add to Trello'
  },
  linear: {
    label: 'Linear',
    connectedText: 'Task sent',
    viewIn: 'View in Linear',
    createText: 'Add to Linear'
  },
  zapier: {
    label: 'Zapier',
    connectedText: 'Connected.',
    viewIn: 'Manage in Zapier',
    link: (projectName: any) => 'https://zapier.com/zapbook/bugherd'
  },
  asana: {
    label: 'Asana',
    connectedText: 'Task sent:',
    viewIn: 'View in Asana',
    createText: 'Add to Asana'
  },
  azureDevOps: {
    label: 'Azure DevOps'
  },
  clickup: {
    label: 'ClickUp',
    connectedText: 'Task sent:',
    viewIn: 'View in ClickUp',
    linkText: 'Link existing task',
    createText: 'Add to ClickUp'
  },
  harvest: {
    label: 'Harvest'
  },
  jira: {
    label: 'Jira',
    connectedText: 'Jira Issue:',
    linkText: 'Link existing issue',
    createText: 'Add to Jira'
  },
  'monday.com': {
    label: 'Monday',
    connectedText: 'Task sent:',
    viewIn: 'View in Monday',
    createText: 'Add to Monday'
  },
  productive: {
    label: 'Productive',
    connectedText: 'Task sent:',
    viewIn: 'View in Productive',
    createText: 'Add to Productive'
  },
  fullStory: {
    label: 'FullStory',
    connectedText: 'FullStory session detected:',
    helpLink: 'https://support.bugherd.com/hc/en-us/articles/6753097081615',
    helpId: 84908
  },
  logRocket: {
    label: 'LogRocket',
    connectedText: 'LogRocket session detected:',
    helpLink: 'https://support.bugherd.com/hc/en-us/articles/6753102790671',
    helpId: 84909
  }
};

export default integrationsData;
