import React, { FC, useState } from 'react';
import { AntdWrapper } from '../../../clients/shared/AntdWrapper';
import { Layout, Flex } from 'antd';
import { LeftNav } from './LeftNav';
import { RightNav } from './RightNav';
import styles from './index.module.css';
import cx from 'classnames';
import useMediaQuery from '../../../../clients/sidebar/views/CreateTask/create_task/useMediaQuery';
import { Props } from './types';
import { MobileNav } from './MobileNav';

const Navbar: FC<Props> = Props => {
  const { user, isActiveOrganization, endpoints } = Props;

  const { isImpersonating, projects, organizations } = user;
  const body = document.body;
  const isMobileView = useMediaQuery('(max-width: 992px)');
  const container = document.getElementById('navbar_container');
  const [isMobileMenuOpen, setMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    if (!isMobileView) return;
    setMobileMenu(!isMobileMenuOpen);

    //prevent user from scrolling when menu is open
    const reactContainer = document.getElementById('ReactApp');
    if (!isMobileMenuOpen && reactContainer) {
      reactContainer.style.overflow = 'hidden';
    } else if (isMobileMenuOpen && reactContainer) {
      reactContainer.style.overflow = 'unset';
    }
  };

  const latestProjects = projects.sort(
    (a, b) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
  );

  const multipleOrganizations = organizations?.length > 1;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDarkMode, setDarkMode] = useState(
    () => localStorage.getItem('theme') || 'light'
  );

  const openElevio = e => {
    e.preventDefault();
    //@ts-expect-error
    // eslint-disable-next-line no-unused-expressions
    window._elev?.openHome();
  };

  const toggleDarkTheme = () => {
    const newTheme = isDarkMode === 'light' ? 'dark-theme' : 'light';
    localStorage.setItem('theme', newTheme);
    setDarkMode(isDarkMode === 'light' ? 'dark-theme' : 'light');
    body.classList.toggle('dark-theme');
  };

  return (
    <Layout className="layout">
      <div className={styles.navWrapper}>
        <div
          className={cx(styles.navOuter, {
            [styles.navHidden]: !isMobileMenuOpen
          })}
        >
          <div
            className={cx(styles.navbarWrapper, {
              [styles.impersonating]: isImpersonating,
              [styles.navHidden]: isMobileMenuOpen && isMobileView
            })}
          >
            <Flex justify="space-between" gap="middle" align="center">
              <LeftNav
                endpoints={endpoints}
                user={user}
                isMobileView={isMobileView}
                container={container}
                latestProjects={latestProjects}
                multipleOrganizations={multipleOrganizations}
                toggleMobileMenu={toggleMobileMenu}
                isMobileMenuOpen={isMobileMenuOpen}
                organizations={organizations}
              />

              <RightNav
                isActiveOrganization={isActiveOrganization}
                endpoints={endpoints}
                user={user}
                isMobileView={isMobileView}
                container={container}
                openElevio={openElevio}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                toggleMobileMenu={toggleMobileMenu}
                toggleDarkMode={toggleDarkTheme}
              />
            </Flex>
          </div>
        </div>
        {isMobileView && (
          <MobileNav
            isMobileMenuOpen={isMobileMenuOpen}
            container={container}
            isActiveOrganization={isActiveOrganization}
            endpoints={endpoints}
            user={user}
            isMobileView
            toggleMobileMenu={toggleMobileMenu}
            latestProjects={latestProjects}
            multipleOrganizations={multipleOrganizations}
            openElevio={openElevio}
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </div>
    </Layout>
  );
};

export default (props: Props) => (
  <AntdWrapper>
    <Navbar {...props} />
  </AntdWrapper>
);
