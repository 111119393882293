export const en = {
  projectTeam: 'Project Team',
  generalTitle: 'General Settings',
  integrationsTitle: 'Integrations',
  installBugherd: 'Install BugHerd',
  sidebar: 'Sidebar',
  tags: 'Tags',
  commitHook: 'Commit Hook',
  exportBugs: 'Export Bugs',
  disableDelete: 'Disable/delete project',
  advanced: 'Advanced',
  installBugherdTitle: 'Install BugHerd to start capturing website feedback.',
  securityTitle: 'Security',
  sidebarSettingsTitle: 'Sidebar Settings',
  editTagsTitle: 'Edit Tags',
  commitTitle: 'Set up a commit hook',
  advancedTitle: 'Advanced Settings',
  disableTitle: 'Disable/Delete project',
  disableCopy:
    "Here you can update your project settings. Please make sure you want to delete your project as you won't be able to retrieve it when it's gone.",
  disableButton: 'Project disabled',
  deleteButton: 'Delete project',
  enableButton: 'Project enabled',
  copy: 'Copy',
  guestPermissions: 'Guest Permissions',
  commitIntro:
    'You can configure your Version Control System with a hook and when you reference a BugHerd task, the commit message is added as a comment.',
  commitGit: `Copy and paste the URL below in to your GitHub project under Settings > Webhooks.`,
  commitGitlab: `Copy and paste the URL below in to your GitLab project under Settings > Integrations.`,
  commitBitbucket: `Copy and paste the URL below in to your Bitbucket project under Settings > Integrations > Webhooks.`
};

export type Translations = typeof en;
