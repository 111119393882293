import { getApplication } from 'models/Application';

export interface UserAccess {
  viewUserActivities: boolean;
  createProject: boolean;
  viewKanban: boolean;
  disableProject: boolean;
  deleteProject: boolean;
  editOrganization: boolean;
  billing: boolean;
  customLogosAllowed: boolean;
  team: boolean;
  sidebarViewStatuses: boolean;
  taskRecordVideo: boolean;
  guestsSeeGuests: boolean;
  taskViewAssignees: boolean;
  canViewAssets: boolean;
}

export const accessTo = (ability: string): boolean => {
  const application = getApplication();

  return application ? application.accessTo(ability) : false;
};
