export const en = {
  resume: 'Resume',
  switch: 'Switch',
  reactivate: 'Reactivate',
  upgrade: 'Upgrade',
  downgrade: 'Downgrade',
  subscribe: 'Subscribe',
  usageExceeded: 'Usage Exceeded',
  currentPlan: 'Your Current Plan',
  perMonth: 'per month',
  perYear: 'per year',
  unlimitedUsers: 'Unlimited users',
  twoMonthsFree: 'Includes 2 Months Free.',
  couponToBeApplied: 'Coupon to be applied',
  discountApplied: 'Discount applied',
  plusEverythingOnPremium: 'Plus everything on Premium',
  unlimitedGuests: 'Unlimited Guests',
  unlimitedProjects: 'Unlimited Projects',
  videoFeedback: 'Video Feedback',
  screenshotsExtension: 'Screenshots (requires Browser Extension)',
  storage10: '10 GB Storage',
  zapier: 'Zapier Integration',
  intergrations: 'Zapier, Slack, GitHub, Basecamp integrations',
  annotations: 'Annotations (via Browser Extension only)',
  publicFeedback: 'Public Feedback Widget',
  storage20: '20 GB Storage',
  plusStandard: 'Plus everything on Standard',
  screenshotsNoExtension: 'Screenshots (without a Browser Extension)',
  customBranding: 'Custom branding/logo',
  guestsSeeGuests: 'Guests can see other guest feedback',
  storage50: '50 GB Storage',
  membersComment: 'Members only comments',
  plusStudio: 'Plus everything on Studio',
  storage150: '150 GB Storage',
  customHeader: 'Custom',
  customMember: ' Custom member limit',
  dedicatedSuccessManager: 'Dedicated Success Manager',
  onboardingAndTraining: 'Onboarding & training support',
  upltimeSla: '99.9% uptime SLA',
  plusPremium: 'Plus everything on Premium',
  customContactUs: 'Contact us for pricing',
  singleSignOn: 'SSO (add on)',
  per: ' ($8/mo per additional user)'
};
export type Translations = typeof en;
