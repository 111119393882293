import React from 'react';

import Loader from 'components/loader';
import { UserAccess } from 'models/Access';
import { Project } from 'models/Project';

import styles from './index.module.css';
import ProjectCard from './card';

type Props = {
  loading: boolean;
  projects: Project[];
  accessTo: UserAccess;
  setShowRefresh: (showRefresh: boolean) => void;
  container: HTMLDivElement;
  hasUnreadCommentsExperiment: boolean;
  currentUserId: number;
};

const ProjectCards = ({
  loading,
  projects,
  accessTo,
  setShowRefresh,
  container,
  hasUnreadCommentsExperiment,
  currentUserId
}: Props) => {
  const getPopupContainer = () => container;

  return (
    <Loader spinning={loading} size="large">
      <div className={styles.cardBox}>
        {projects.map((project, index) => (
          <ProjectCard
            {...{
              key: project.id,
              project,
              accessTo,
              getPopupContainer,
              setShowRefresh,
              hasUnreadCommentsExperiment,
              currentUserId,
              index
            }}
          />
        ))}
      </div>
    </Loader>
  );
};

export default ProjectCards;
