import React, {
  FC,
  useState,
  useEffect,
  useRef,
  MutableRefObject
} from 'react';
import cx from 'classnames';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Alert, message, Progress, Switch, Button } from 'antd';
import resizeSidebar from '../../../../javascript/utils/resizeSidebar';
import WhyModal from './whyModal';
import ScreenshotModal from './screenshotModal';
import Loader from '../../../../javascript/components/loader';
import ScreenshotPreview from '../../../../javascript/components/ScreenshotPreview';
import { Edit as EditIcon } from 'lucide-react';
import Video from './Video';
import track from '../../../../javascript/utils/analytics';
import { newPins as pins } from '../../../../javascript/utils/getBugPins';
import styles from './index.module.css';
import getScreenshotStyles from '../../../../javascript/utils/getScreenshotStyles';
import * as translations from './strings';
import { getLangKey } from '../../../../javascript/models/Application';
import { get } from '../../../../javascript/utils/fetch';
import { detect, BrowserInfo } from 'detect-browser';
import { HandleRegenerateScreenshotProps } from 'appJS/hooks/useScreenshotView';

type Input = {
  value: string;
  checked?: boolean;
};

type Inputs = {
  [key: number]: Input;
};

type Viewport = {
  height: number;
  offsetLeft: number;
  offsetTop: number;
  pageLeft: number;
  pageTop: number;
  scale: number;
  width: number;
};

type PolyfillProperties = {
  devicePixelRatio: number;
  innerHeight: number;
  innerWidth: number;
  inputs: Inputs;
  offsets: {};
  scrollLeft: number;
  scrollTop: number;
  scrollbarWidth: number;
  url: string;
  userAgent: string;
  viewport: Viewport;
};

export type FailedScreenshotData = {
  documentElement: string;
  documentStyleSheets: [];
  properties: PolyfillProperties;
  basicAuthPresent?: boolean;
  basicAuthUrl?: string;
};

const containerHeight = 100;

// @ts-ignore
const strings = translations[getLangKey()];

type ScreenshotError = {
  createdAt: string;
  error: string;
  screenshotData: FailedScreenshotData;
};

export type Props = {
  priorityId: number;
  taskId: number;
  projectId: number;
  createdAt: string;
  isAdminView?: boolean;
  currentUser: {
    role: string;
    id: number;
  };
  orgId: number;
  screenshotData: {
    screenshotError: ScreenshotError[] | null;
    url: string;
    coordinates: any;
    screenshotKey: string;
  };
  setScreenshot: (screenshotUrl: string) => void;
  url: string;
  container: HTMLElement;
  taskUrls: {
    relativeUrl: string;
  };
  mobileScreenshots: boolean;
  gotoTask: () => void;
  videoRef: MutableRefObject<null | HTMLElement>;
  noSelector: boolean;
  handleScreenshotToggle: (isCroppedView: boolean) => void;
  isCroppedView: boolean;
  owner: {
    email: string;
    id: number;
  };
  billingRights: boolean;
  handleRegenerateScreenshot: ({
    taskId,
    screenshotData,
    apiDomain,
    projectId,
    hasAccess,
    basicAuthUrl
  }: HandleRegenerateScreenshotProps) => void;
  apiDomain: string;
  screenshotServerUrl: string;
  basicAuthUrl: string;
  retryScreenshotAt?: string;
};

const Screenshot: FC<Props> = ({
  priorityId,
  taskId,
  projectId,
  createdAt,
  isAdminView,
  orgId,
  currentUser: { role, id },
  screenshotData,
  setScreenshot,
  url,
  container,
  taskUrls,
  mobileScreenshots,
  gotoTask,
  videoRef,
  noSelector,
  handleScreenshotToggle,
  isCroppedView,
  owner,
  billingRights,
  handleRegenerateScreenshot,
  apiDomain,
  retryScreenshotAt,
  screenshotServerUrl,
  basicAuthUrl
}) => {
  const containerRef: MutableRefObject<any> = useRef(null);

  const [showScreenshot, setShowScreenshot] = useState<boolean>(false);
  const [showWhy, setShowWhy] = useState<boolean>(false);
  const [minimized, setMinimized] = useState<boolean>(false);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [screenshotProgress, setScreenshotProgress] = useState<number>();
  const [annotating, setAnnotating] = useState<boolean>(false);

  const updateDimensions = () => {
    if (containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect();
      if (width !== containerWidth) setContainerWidth(width);
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    updateDimensions();
  });

  const resetIframe = resizeSidebar.reset;

  const _resizeIframe = () => resizeSidebar.resize('100%');

  const toggleModal = async (isOpen: boolean, modalName: string) => {
    if (typeof isAdminView !== 'undefined' && !isAdminView) {
      isOpen ? await resetIframe() : await _resizeIframe();
    }
    modalName === 'showWhy' ? setShowWhy(!isOpen) : setShowScreenshot(!isOpen);
  };

  const canSeeWhyModalLink = !(role === 'guest' || role === 'collaborator');

  const hasScreenshot = screenshotData && screenshotData.url !== null;

  const dateNow = new Date().getTime();
  const processingWaitPeriod = 300000;

  const isProcessing =
    (!hasScreenshot &&
      dateNow < new Date(createdAt).getTime() + processingWaitPeriod) ||
    (!hasScreenshot &&
      retryScreenshotAt &&
      dateNow < new Date(retryScreenshotAt).getTime() + processingWaitPeriod);

  const getError = () => {
    let type = '';
    let retryData: FailedScreenshotData | undefined = undefined;
    const { screenshotError: allScreenshotErrors, url } = screenshotData;

    const screenshotError = allScreenshotErrors?.filter(
      error => !retryScreenshotAt || error.createdAt > retryScreenshotAt
    );

    if (screenshotError) {
      if (screenshotError?.length) {
        screenshotError.sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
        const { error, screenshotData } = screenshotError[
          screenshotError.length - 1
        ];
        type = error;
        retryData = screenshotData;
      } else if (!url && !isProcessing) {
        type = 'generic';
      }
    }

    return { type, retryData };
  };

  const { type: screenshotError, retryData } = getError();
  const hasError = !!screenshotError;

  const getAlertElements = () => {
    const showGenerating = isProcessing && !hasError;
    const alertType = showGenerating ? 'info' : 'warning';
    const showNotGenerated = !showGenerating;
    if (minimized)
      return (
        <div className={styles.minimizedContainer}>
          <span className={styles.minimizedLabel}>{strings.label}</span>
          <ExclamationCircleFilled
            className={styles.alertIcon}
            onClick={() => setMinimized(!minimized)}
          />
        </div>
      );

    return (
      <Alert
        className={styles.screenshotAlert}
        type={alertType}
        message={
          <span className={styles.alertMessage}>
            {showGenerating && (
              <div className={styles.processingContainer}>
                <Loader className={styles.loader} />
                <span className={styles.processingContent}>
                  {strings.generating}
                </span>
              </div>
            )}

            {showNotGenerated && (
              <span>
                {strings.notCaptured}
                {canSeeWhyModalLink && (
                  <a onClick={() => toggleModal(showWhy, 'showWhy')}>
                    ({strings.why})
                  </a>
                )}
              </span>
            )}

            {retryData && (
              <Button
                className={styles.retry}
                type="primary"
                onClick={() =>
                  handleRegenerateScreenshot({
                    taskId,
                    screenshotData: retryData,
                    apiDomain,
                    projectId,
                    hasAccess: mobileScreenshots,
                    screenshotServerUrl,
                    basicAuthUrl
                  })
                }
                shape="round"
                size="small"
              >
                {strings.retry}
              </Button>
            )}
          </span>
        }
        closable={showNotGenerated}
        showIcon={showNotGenerated}
        onClose={() => setMinimized(!minimized)}
      />
    );
  };

  const taskOrigin = Object.keys(screenshotData).length ? 'sidebar' : 'app';

  const dataURIToBlob = (uri: string) => {
    const parts: string[] = uri.split(',');
    const binary = atob(parts[1]);
    const type: string = parts[0];
    const array: number[] = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array).buffer], { type: type });
  };

  const getSlot = () =>
    get(
      `${url}/projects/${projectId}/tasks/${taskId}/attachments/new.json?screenshot=true`
    );

  const handleAnnotation = async (uri: any) => {
    setAnnotating(false);
    setScreenshotProgress(0);

    const slot = await getSlot();
    const { key, policy, signature, S3URL } = slot;
    const fd = new FormData();
    fd.append('key', key + '/screenshot.jpg');
    fd.append('AWSAccessKeyId', 'AKIAIK2PYU6R3QKHBZDA');
    fd.append('acl', 'public-read');
    fd.append('policy', policy);
    fd.append('signature', signature);
    fd.append('Content-Type', 'image/jpeg');
    fd.append('file', dataURIToBlob(uri));
    const xhr = new XMLHttpRequest();

    xhr.addEventListener('load', () => {
      setScreenshot(S3URL + key + '/screenshot.jpg');
      setScreenshotProgress(undefined);
    });

    xhr.addEventListener('error', () => {
      message.error(strings.wentWrong);
      setScreenshotProgress(undefined);
    });

    xhr.upload.addEventListener(
      'progress',
      ({ loaded, total, lengthComputable }) => {
        if (lengthComputable) {
          setScreenshotProgress(Math.round((loaded / total) * 100));
        }
      }
    );

    xhr.open('POST', S3URL, true);
    xhr.send(fd);

    track('annotate_screenshot', {
      organization: orgId,
      user: id,
      task: taskId
    });
  };

  const handleEditAnnotation = (event: any) => {
    event.preventDefault();
    setAnnotating(true);
    toggleModal(showScreenshot, 'showScreenshot');
  };

  const renderScreenshotElements: boolean = !hasError && hasScreenshot;
  const pinUrl = pins[priorityId];

  if (
    taskOrigin === 'app' ||
    !screenshotData ||
    !(screenshotData.screenshotKey || screenshotData.url)
  )
    return null;
  if (
    hasScreenshot &&
    (screenshotData.url.endsWith('.mp4') ||
      screenshotData.url.endsWith('.webm'))
  ) {
    return (
      <Video
        isAdminView={isAdminView}
        url={screenshotData.url}
        key={screenshotData.url}
        videoRef={videoRef}
      />
    );
  }

  const browserInfo = detect() as BrowserInfo;

  const viewport =
    !hasError && screenshotData.coordinates
      ? getScreenshotStyles({
          containerWidth,
          containerHeight,
          isCroppedView,
          coordinates: screenshotData.coordinates,
          pinUrl,
          isScreenshotServer: !!screenshotData.url?.includes(
            'mobile-screenshot'
          )
        }).viewport
      : {};

  return (
    <div
      className={cx(styles.screenshotContainer, {
        [styles.cropped]: isCroppedView
      })}
    >
      {(hasError || isProcessing) && (
        <div className={styles.unavailableContainer}>
          {getAlertElements()}

          {screenshotError && (
            <WhyModal
              container={container}
              show={showWhy}
              toggleWhy={toggleModal}
              projectId={projectId}
              taskId={taskId}
              orgId={orgId}
              isSidebar={!isAdminView}
              url={url}
              screenshotError={screenshotError}
              hasAccess={mobileScreenshots}
              screenshotKey={screenshotData.screenshotKey}
              canAccessBilling={!!role && role.includes('billing')}
            />
          )}
        </div>
      )}

      {renderScreenshotElements && (
        <div className={styles.screenshotSectionContainer}>
          <ScreenshotModal
            container={container}
            pinUrl={pinUrl}
            show={showScreenshot}
            toggleScreenshot={toggleModal}
            url={screenshotData.url}
            // @ts-ignore
            viewport={viewport}
            onSaveAnnotation={handleAnnotation}
            onCancelAnnotation={() => setAnnotating(false)}
            setAnnotating={setAnnotating}
            annotating={annotating}
            gotoTask={gotoTask}
            taskUrls={taskUrls || {}}
            isAdminView={!!isAdminView}
          />

          <div className={styles.labelAndSwitchContainer}>
            <span className={styles.minimizedLabel}>
              {strings.label}
              {url && (
                <EditIcon
                  className={styles.editIcon}
                  onClick={handleEditAnnotation}
                />
              )}
            </span>
            {(!noSelector || !!screenshotData.coordinates) && (
              <Switch
                key="2"
                onChange={handleScreenshotToggle}
                checkedChildren={strings.focus}
                unCheckedChildren={strings.full}
                size="small"
                className={cx(styles.viewSwitch, {
                  [styles.forIos]: browserInfo.name === 'ios'
                })}
                checked={isCroppedView}
              />
            )}
          </div>
          {typeof screenshotProgress === 'number' ? (
            <div className={styles.progressContainer}>
              <Progress
                percent={screenshotProgress}
                showInfo={false}
                status={screenshotProgress === 100 ? 'active' : 'normal'}
              />
              {strings.addingAnnotations}
            </div>
          ) : (
            <ScreenshotPreview
              coordinates={screenshotData.coordinates}
              priorityId={priorityId}
              isCroppedView={isCroppedView}
              url={screenshotData.url}
              screenshotKey={screenshotData.screenshotKey}
              containerWidth={containerWidth}
              containerHeight={containerHeight}
              containerRef={containerRef}
              onShow={() => toggleModal(showScreenshot, 'showScreenshot')}
              noSelector={noSelector}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Screenshot;
