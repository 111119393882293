import React, { FC, useEffect, useState } from 'react';
import { Image, Spin } from 'antd';
import styles from './styles.module.css';
import cx from 'classnames';
import { getFileType, FileType, MS_DOC_FILE_TYPES } from './getFileType';
import { JsonPreview } from './JsonPreview';
import { CsvPreview } from './CsvPreview';
import { PreviewToolbar } from './PreviewToolbar';
import { MSDocPreview } from './MSDocPreview';
import { TxtPreview } from './TxtPreview';

type Props = {
  previewSource: string | null;
  setPreviewSource: (url: string | null) => void;
  container: HTMLDivElement;
};

type FileData = { [key: string]: any };
type CSVData = { [key: string]: any[] };
type TxtData = { [key: string]: string };

export const AttachmentPreview: FC<Props> = ({
  previewSource,
  setPreviewSource,
  container
}) => {
  const [sourceLoading, setSourceLoading] = useState<boolean>(false);
  const [json, setJson] = useState<FileData[]>([]);
  const [csvData, setCsvData] = useState<CSVData[]>([]);
  const [txt, setTxt] = useState<TxtData[]>([]);

  const extension = previewSource?.split('.').pop();
  const fileType = getFileType(extension || '');
  const isImage = fileType === FileType.Image;
  const isVideo = fileType === FileType.Video;
  const isJson = extension === 'json';
  const isCsv = extension === 'csv';
  const isTxt = extension === 'txt';
  const isMSDoc = MS_DOC_FILE_TYPES.includes(extension || '');

  useEffect(() => {
    if (!previewSource) return;

    let loading = true;
    switch (extension) {
      case 'json':
        loading = !json.find(data => data[previewSource]);
        break;
      case 'csv':
        loading = !csvData.find(data => data[previewSource]);
        break;
      case 'txt':
        loading = !txt.find(data => data[previewSource]);
        break;
      default:
        break;
    }
    setSourceLoading(loading);
  }, [previewSource]);

  if (!previewSource) return null;

  return (
    <Image
      style={{ display: 'none' }}
      src={previewSource}
      onLoad={() => setSourceLoading(false)}
      preview={{
        src: previewSource,
        getContainer: () => container,
        visible: !!previewSource,
        onVisibleChange: visible => {
          if (!visible) setPreviewSource(null);
        },
        toolbarRender: (_, info) => (
          <PreviewToolbar {...info} isImage={isImage} url={previewSource} />
        ),
        imageRender: orgininalNode => {
          return (
            <>
              {sourceLoading && <Spin size="large" />}
              {isImage && orgininalNode}
              <div
                className={cx(styles.previewContainer, {
                  [styles.notImage]: !isImage && !sourceLoading
                })}
              >
                {isVideo && (
                  <video
                    controls
                    onLoadedData={() => setSourceLoading(false)}
                    className={styles.videoElement}
                  >
                    <source src={previewSource} />
                  </video>
                )}
                {isJson && (
                  <JsonPreview
                    source={previewSource}
                    setSourceLoading={setSourceLoading}
                    sourceLoading={sourceLoading}
                    json={json}
                    setJson={setJson}
                  />
                )}
                {isCsv && (
                  <CsvPreview
                    source={previewSource}
                    setSourceLoading={setSourceLoading}
                    sourceLoading={sourceLoading}
                    csvData={csvData}
                    setCsvData={setCsvData}
                  />
                )}
                {isMSDoc && (
                  <MSDocPreview
                    url={previewSource}
                    onLoaded={() => setSourceLoading(false)}
                  />
                )}
                {isTxt && (
                  <TxtPreview
                    source={previewSource}
                    setSourceLoading={setSourceLoading}
                    sourceLoading={sourceLoading}
                    txt={txt}
                    setTxt={setTxt}
                  />
                )}
              </div>
            </>
          );
        }
      }}
    />
  );
};
