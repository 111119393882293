import { onLCP, onINP, onFID, onCLS, INPReportCallback } from 'web-vitals';
import track from 'appJS/utils/analytics';
import { getCurrentUserId } from 'jsModels/Organization';

// Sample so we don't send too many events to mixpanel
const SAMPLING_AMOUNT = 0.003;
function sendToAnalytics(metric) {
  track(metric.name, {
    user: getCurrentUserId(), // helps with reducing spend on analytics
    value: metric.value,
    metric_unique_id: metric.id,
    path: window.location.pathname,
    triage_count: window.project_board_props?.triageCount,
    kanban_count: window.project_board_props?.kanbanCount,
    // This may not be 100% accurate for general metrics but still useful to send
    virtualisation_enabled: window.BUGHERD_VIRTUALISED_KANBAN_LOADED || false
  });
}

const reportBulkActionCheckbox: INPReportCallback = metric => {
  const elem = metric.entries[0].target;
  const event = elem?.getAttribute('data-performance-event');

  const trackedEvents = ['BulkActionsCheckbox', 'KanbanCard'];

  const eventNames = {
    BulkActionsCheckbox: 'bulk actions check INP',
    KanbanCard: 'kanban card INP'
  };

  const shouldTrackEvent = trackedEvents.includes(event);

  if (!shouldTrackEvent) return;

  track(eventNames[event], {
    user: getCurrentUserId(), // helps with reducing spend on analytics
    value: metric.value,
    metric_unique_id: metric.id,
    path: window.location.pathname,
    triage_count: window.project_board_props?.triageCount,
    kanban_count: window.project_board_props?.kanbanCount,
    // Basic differentation between virtualised and non-virtualised kanban
    virtualisation_enabled: window.BUGHERD_VIRTUALISED_KANBAN_LOADED || false
  });
};

const setupWebVitals = () => {
  onINP(reportBulkActionCheckbox, {
    reportAllChanges: true,
    durationThreshold: 10
  });

  if (Math.random() > SAMPLING_AMOUNT) return;
  onLCP(sendToAnalytics);
  onINP(sendToAnalytics);
  onFID(sendToAnalytics);
  onCLS(sendToAnalytics);

  console.log('Web Vitals Enabled');
};

setupWebVitals();
