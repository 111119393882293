import * as translations from './strings';
import { getLangKey } from 'models/Language';

const strings = translations[getLangKey()];
export const getPlanFeatures = ({ planHandle }: { planHandle: string }) => {
  switch (planHandle) {
    case 'midi':
    case 'standard_yearly':
      return [
        strings.unlimitedProjects,
        strings.unlimitedGuests,
        strings.screenshotsExtension,
        strings.annotations,
        strings.intergrations,
        strings.publicFeedback,
        strings.storage10
      ];
      break;
    case 'studio':
    case 'studio_yearly':
      return [strings.plusStandard, strings.videoFeedback];
      break;
    case 'maxi':
    case 'premium_yearly':
      return [
        strings.screenshotsNoExtension,
        strings.customBranding,
        strings.guestsSeeGuests,
        strings.membersComment,
        strings.storage50,
        strings.plusStandard
      ];
      break;
    case 'deluxe':
    case 'deluxe_yearly':
      return [strings.storage150, strings.plusEverythingOnPremium];
      break;
    default:
      return [];
  }
};
