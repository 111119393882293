import { Plan } from '../types';
import { getAvailablePlans } from '../getAvailablePlans';

export const getRecommendedMax = (name: string) => {
  const maxAdditionalSeats: Record<string, number> = {
    Standard: 4,
    Studio: 8,
    Premium: 13
  };
  const planName = name.replace('Yearly', '').trim();
  const recommendedMax = maxAdditionalSeats[planName];
  return !!recommendedMax ? recommendedMax : undefined;
};

export const getUpgradePlan = ({
  organizationId,
  name
}: {
  organizationId: string;
  name: string;
}) => {
  const upgradePlan: Record<string, string> = {
    Standard: 'Studio',
    Studio: 'Premium',
    Premium: 'Deluxe',
    'Standard Yearly': 'Studio Yearly',
    'Studio Yearly': 'Premium Yearly',
    'Premium Yearly': 'Deluxe Yearly'
  };
  const plans = getAvailablePlans(organizationId);
  const availablePlans = plans.availablePlans as Plan[];
  return availablePlans.find(plan => upgradePlan[name] === plan.name);
};
