import React, { FC } from 'react';
import { Layout } from 'antd';
import styles from './index.module.css';

const { Content } = Layout;

const UserPageLayout: FC = ({ children }) => {
  return (
    <Layout className={styles.layout}>
      <Content>{children}</Content>
    </Layout>
  );
};

export default UserPageLayout;
