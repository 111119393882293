export const timezones = {
  'Pacific/Pago_Pago': '(GMT-11:00) Pago Pago',
  'Pacific/Honolulu': '(GMT-10:00) Hawaii Time',
  'America/Los_Angeles': '(GMT-08:00) Pacific Time',
  'America/Tijuana': '(GMT-08:00) Pacific Time - Tijuana',
  'America/Denver': '(GMT-07:00) Mountain Time',
  'America/Phoenix': '(GMT-07:00) Mountain Time - Arizona',
  'America/Mazatlan': '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan',
  'America/Chicago': '(GMT-06:00) Central Time',
  'America/Mexico_City': '(GMT-06:00) Central Time - Mexico City',
  'America/Regina': '(GMT-06:00) Central Time - Regina',
  'America/Guatemala': '(GMT-06:00) Guatemala',
  'America/Bogota': '(GMT-05:00) Bogota',
  'America/New_York': '(GMT-05:00) Eastern Time',
  'America/Lima': '(GMT-05:00) Lima',
  'America/Caracas': '(GMT-04:30) Caracas',
  'America/Halifax': '(GMT-04:00) Atlantic Time - Halifax',
  'America/Guyana': '(GMT-04:00) Guyana',
  'America/La_Paz': '(GMT-04:00) La Paz',
  'America/Argentina/Buenos_Aires': '(GMT-03:00) Buenos Aires',
  'America/Godthab': '(GMT-03:00) Godthab',
  'America/Montevideo': '(GMT-03:00) Montevideo',
  'America/St_Johns': '(GMT-03:30) Newfoundland Time - St. Johns',
  'America/Santiago': '(GMT-03:00) Santiago',
  'America/Sao_Paulo': '(GMT-02:00) Sao Paulo',
  'Atlantic/South_Georgia': '(GMT-02:00) South Georgia',
  'Atlantic/Azores': '(GMT-01:00) Azores',
  'Atlantic/Cape_Verde': '(GMT-01:00) Cape Verde',
  'Africa/Casablanca': '(GMT+00:00) Casablanca',
  'Europe/Dublin': '(GMT+00:00) Dublin',
  'Europe/Lisbon': '(GMT+00:00) Lisbon',
  'Europe/London': '(GMT+00:00) London',
  'Africa/Monrovia': '(GMT+00:00) Monrovia',
  'Africa/Algiers': '(GMT+01:00) Algiers',
  'Europe/Amsterdam': '(GMT+01:00) Amsterdam',
  'Europe/Berlin': '(GMT+01:00) Berlin',
  'Europe/Brussels': '(GMT+01:00) Brussels',
  'Europe/Budapest': '(GMT+01:00) Budapest',
  'Europe/Belgrade': '(GMT+01:00) Central European Time - Belgrade',
  'Europe/Prague': '(GMT+01:00) Central European Time - Prague',
  'Europe/Copenhagen': '(GMT+01:00) Copenhagen',
  'Europe/Madrid': '(GMT+01:00) Madrid',
  'Europe/Paris': '(GMT+01:00) Paris',
  'Europe/Rome': '(GMT+01:00) Rome',
  'Europe/Stockholm': '(GMT+01:00) Stockholm',
  'Europe/Vienna': '(GMT+01:00) Vienna',
  'Europe/Warsaw': '(GMT+01:00) Warsaw',
  'Europe/Athens': '(GMT+02:00) Athens',
  'Europe/Bucharest': '(GMT+02:00) Bucharest',
  'Africa/Cairo': '(GMT+02:00) Cairo',
  'Asia/Jerusalem': '(GMT+02:00) Jerusalem',
  'Africa/Johannesburg': '(GMT+02:00) Johannesburg',
  'Europe/Helsinki': '(GMT+02:00) Helsinki',
  'Europe/Kiev': '(GMT+02:00) Kiev',
  'Europe/Kaliningrad': '(GMT+02:00) Moscow-01 - Kaliningrad',
  'Europe/Riga': '(GMT+02:00) Riga',
  'Europe/Sofia': '(GMT+02:00) Sofia',
  'Europe/Tallinn': '(GMT+02:00) Tallinn',
  'Europe/Vilnius': '(GMT+02:00) Vilnius',
  'Europe/Istanbul': '(GMT+03:00) Istanbul',
  'Asia/Baghdad': '(GMT+03:00) Baghdad',
  'Africa/Nairobi': '(GMT+03:00) Nairobi',
  'Europe/Minsk': '(GMT+03:00) Minsk',
  'Asia/Riyadh': '(GMT+03:00) Riyadh',
  'Europe/Moscow': '(GMT+03:00) Moscow+00 - Moscow',
  'Asia/Tehran': '(GMT+03:30) Tehran',
  'Asia/Baku': '(GMT+04:00) Baku',
  'Europe/Samara': '(GMT+04:00) Moscow+01 - Samara',
  'Asia/Tbilisi': '(GMT+04:00) Tbilisi',
  'Asia/Yerevan': '(GMT+04:00) Yerevan',
  'Asia/Kabul': '(GMT+04:30) Kabul',
  'Asia/Karachi': '(GMT+05:00) Karachi',
  'Asia/Yekaterinburg': '(GMT+05:00) Moscow+02 - Yekaterinburg',
  'Asia/Tashkent': '(GMT+05:00) Tashkent',
  'Asia/Colombo': '(GMT+05:30) Colombo',
  'Asia/Almaty': '(GMT+06:00) Almaty',
  'Asia/Dhaka': '(GMT+06:00) Dhaka',
  'Asia/Rangoon': '(GMT+06:30) Rangoon',
  'Asia/Bangkok': '(GMT+07:00) Bangkok',
  'Asia/Jakarta': '(GMT+07:00) Jakarta',
  'Asia/Krasnoyarsk': '(GMT+07:00) Moscow+04 - Krasnoyarsk',
  'Asia/Shanghai': '(GMT+08:00) China Time - Beijing',
  'Asia/Hong_Kong': '(GMT+08:00) Hong Kong',
  'Asia/Kuala_Lumpur': '(GMT+08:00) Kuala Lumpur',
  'Asia/Irkutsk': '(GMT+08:00) Moscow+05 - Irkutsk',
  'Asia/Singapore': '(GMT+08:00) Singapore',
  'Asia/Taipei': '(GMT+08:00) Taipei',
  'Asia/Ulaanbaatar': '(GMT+08:00) Ulaanbaatar',
  'Australia/Perth': '(GMT+08:00) Western Time - Perth',
  'Asia/Yakutsk': '(GMT+09:00) Moscow+06 - Yakutsk',
  'Asia/Seoul': '(GMT+09:00) Seoul',
  'Asia/Tokyo': '(GMT+09:00) Tokyo',
  'Australia/Darwin': '(GMT+09:30) Central Time - Darwin',
  'Australia/Brisbane': '(GMT+10:00) Eastern Time - Brisbane',
  'Pacific/Guam': '(GMT+10:00) Guam',
  'Asia/Magadan': '(GMT+10:00) Moscow+07 - Magadan',
  'Asia/Vladivostok': '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk',
  'Pacific/Port_Moresby': '(GMT+10:00) Port Moresby',
  'Australia/Adelaide': '(GMT+10:30) Central Time - Adelaide',
  'Australia/Hobart': '(GMT+11:00) Eastern Time - Hobart',
  'Australia/Sydney': '(GMT+11:00) Eastern Time - Melbourne, Sydney',
  'Pacific/Guadalcanal': '(GMT+11:00) Guadalcanal',
  'Pacific/Noumea': '(GMT+11:00) Noumea',
  'Pacific/Majuro': '(GMT+12:00) Majuro',
  'Asia/Kamchatka': '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy',
  'Pacific/Auckland': '(GMT+13:00) Auckland',
  'Pacific/Fakaofo': '(GMT+13:00) Fakaofo',
  'Pacific/Fiji': '(GMT+13:00) Fiji',
  'Pacific/Tongatapu': '(GMT+13:00) Tongatapu',
  'Pacific/Apia': '(GMT+14:00) Apia'
};

export default timezones;
