import React, {
  useState,
  useEffect,
  MouseEventHandler,
  MouseEvent
} from 'react';
import { Card, Button, message, Tooltip } from 'antd';
import { formatDistanceStrict, differenceInMinutes, parseISO } from 'date-fns';
import Actions from './actions';

import defaultCover from '../images/project.png';
import {
  MousePointer as MouseArrow,
  Camera as Refresh,
  ExternalLink as LinkIcon,
  MapPin
} from 'lucide-react';
import HelpArticleButton from 'utils/HelpArticleButton';
import styles from './style.module.css';
import { post } from 'utils/fetch';
import cx from 'classnames';

import * as translations from './strings';
import { getLangKey } from 'models/Language';
import { ProjectIndicator } from '../../../../../clients/shared/CommentIndicator/CommentIndicator';
import { Project } from 'jsModels/Project';
import { useQuery } from '@apollo/client';
import { GET_UNREAD_COUNT } from '../queries';

const strings = translations[getLangKey()];

type Props = {
  accessTo: {
    createProject: boolean;
    viewKanban: boolean;
    viewUserActivities: boolean;
    disableProject: boolean;
    deleteProject: boolean;
  };
  authenticityToken?: string;
  project: Project;
  setShowRefresh: () => void;
  getPopupContainer: () => any;
  hasUnreadCommentsExperiment: boolean;
  index?: number;
};
const ProjectCard: React.FC<Props> = props => {
  const {
    accessTo,
    project,
    getPopupContainer,
    setShowRefresh,
    hasUnreadCommentsExperiment,
    index
  } = props;

  const {
    devurl,
    id,
    name,
    updatedAt,
    coverUpdateRequestedAt,
    coverScreenshot,
    openTasksCount,
    triageTasksCount,
    isActive,
    organizationId,
    assets
  } = project;

  const {
    viewKanban,
    createProject,
    viewUserActivities,
    disableProject,
    deleteProject
  } = accessTo;
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [awaitingCover, setAwaitingCover] = useState(false);
  const [
    updatedNumberOfUnreadComments,
    setUpdatedNumberOfUnreadComments
  ] = useState(0);
  const [
    updatedNumberOfUnreadMentions,
    setUpdatedNumberOfUnreadMentions
  ] = useState(0);
  const [hasQueryBeenExecuted, setHasQueryBeenExecuted] = useState(false);

  const { data, error, loading: unreadCountsLoading } = useQuery(
    GET_UNREAD_COUNT,
    {
      variables: { projectId: id },
      skip: hasQueryBeenExecuted,
      onCompleted: () => setHasQueryBeenExecuted(true)
    }
  );

  useEffect(() => {
    if (error) {
      if (window.bugsnagClient) {
        window.bugsnagClient.notify(error);
      } else {
        console.error(error);
      }
    }
    if (!data || !hasUnreadCommentsExperiment) return;

    const unreadCounts = data?.currentUser?.userProject;
    setUpdatedNumberOfUnreadComments(unreadCounts?.numberOfUnreadComments || 0);
    setUpdatedNumberOfUnreadMentions(unreadCounts?.numberOfUnreadMentions || 0);
  }, [data, error, hasUnreadCommentsExperiment]);

  const isDevUrlPresent = devurl && devurl.length > 0;
  const hasAssets = assets && assets.length > 0;
  const projectURL = isDevUrlPresent ? devurl : `/projects/${id}`;
  const getCardLink = viewKanban ? `/projects/${id}` : devurl;

  const getReadableTime = formatDistanceStrict(new Date(), parseISO(updatedAt));
  const coverUrl =
    !coverScreenshot || coverScreenshot.includes('bugherd-attachments')
      ? coverScreenshot
      : `https://s3-us-west-2.amazonaws.com/bugherd-project-covers/${coverScreenshot}`;

  const getViewCardText = () => {
    if (isDevUrlPresent) {
      return strings.viewWebsite;
    } else if (hasAssets) {
      return strings.viewAsset;
    } else {
      return strings.viewProject;
    }
  };

  const retryScreenshot = event => {
    event.preventDefault();
    event.stopPropagation();

    setProcessing(true);
    setLoading(true);

    post(`/projects/${id}/generate_cover`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
      .then(() => {
        setProcessing(false);
        setLoading(false);
        message.success(strings.success(!!coverUrl));
        if (coverUrl) {
          setAwaitingCover(true);
        }
      })
      .catch(error => {
        message.error(strings.wentWrong);
      });
  };
  const getUpdateButton = () => {
    const under3Mins =
      differenceInMinutes(new Date(), new Date(coverUpdateRequestedAt)) < 3;

    if (!coverUrl && isDevUrlPresent) {
      if (under3Mins) {
        return <p>{strings.gettingCover}</p>;
      } else {
        return (
          <div>
            <button
              className={styles.retry}
              size="small"
              disabled={processing}
              onClick={!processing ? retryScreenshot : null}
            >
              {processing ? (
                strings.pleaseWait
              ) : (
                <span className={styles.buttonInner}>
                  <Refresh className={styles.retryIcon} />
                  {strings.getCover}
                </span>
              )}
            </button>
            <span className={styles.errorAlert}>
              {strings.notCreated} (
              <HelpArticleButton articleId="84838" style={{ color: '#406cff' }}>
                {strings.why}
              </HelpArticleButton>
              )
            </span>
          </div>
        );
      }
    } else if (awaitingCover) {
      return <p>{strings.gettingCover}</p>;
    }
  };

  const handleCardClick: MouseEventHandler<HTMLElement> = (
    event: MouseEvent<HTMLElement>
  ) => {
    if (!viewKanban) {
      event.preventDefault();
      event.stopPropagation();
      window.location.href = `/organizations/${organizationId}/projects/${id}`;
    }
  };

  const handleReadAll = async () => {
    post(`/projects/${id}/mark_tasks_as_read`, {
      task_ids: null
    })
      .then(response => {
        setUpdatedNumberOfUnreadComments(0);
        setUpdatedNumberOfUnreadMentions(0);
        message.success(strings.markAsReadSuccess);
      })
      .catch(error => {
        message.error(strings.markAsReadFailure);
      });
  };

  return (
    <div
      className={cx(styles.cardOuter, styles.staggerInAnimation, {
        [styles.guestView]: !viewKanban
      })}
      style={{ '--index': index }}
    >
      <a
        title={getCardLink}
        href={getCardLink}
        className={styles.cardLink}
        target={viewKanban ? '_self' : '_blank'}
        rel="noopener"
        onClick={handleCardClick}
      >
        {viewUserActivities && (
          <Tooltip
            {...{
              getPopupContainer,
              title: (
                <span className={styles.title}>
                  {strings.tasksCountTitle(openTasksCount, triageTasksCount)}
                </span>
              )
            }}
          >
            <span
              className={cx(styles.openCount, {
                [styles.openFeedback]: triageTasksCount > 0
              })}
            >
              <MapPin className={styles.mapPinIcon} />
              {openTasksCount + triageTasksCount}
            </span>
          </Tooltip>
        )}
        <Card
          className={isActive ? styles.card : styles.inactiveCard}
          bordered={false}
          cover={
            <React.Fragment>
              <img
                width={280}
                height={178}
                loading="lazy"
                className={styles.img}
                src={awaitingCover || !coverUrl ? defaultCover : coverUrl}
              />
              {createProject && isActive && (
                <div className={styles.updateButtonContainer}>
                  {getUpdateButton()}
                </div>
              )}

              {!isActive && (
                <div className={styles.updateButtonContainer}>
                  <span className={styles.disabled}>{strings.disabled}</span>
                </div>
              )}
            </React.Fragment>
          }
        >
          <div className={styles.project}>
            <h3>{name}</h3>
          </div>
          {viewKanban && (
            <div className={styles.cardBody}>
              <>
                {hasUnreadCommentsExperiment && (
                  <ProjectIndicator
                    numberOfUnreadComments={updatedNumberOfUnreadComments}
                    numberOfUnreadMentions={updatedNumberOfUnreadMentions}
                    isLoading={unreadCountsLoading}
                  />
                )}
              </>
              <div className={styles.updatedAndRefresh}>
                <span className={styles.updatedAgo}>
                  {strings.updatedAgo(getReadableTime)}
                </span>
                {isDevUrlPresent && (
                  <Button
                    type="secondary"
                    size="small"
                    className={styles.refreshButton}
                    loading={loading}
                    onClick={retryScreenshot}
                  >
                    {!loading && <Refresh className={styles.viewIcon} />}
                    {coverScreenshot
                      ? strings.refresh
                      : strings.coverScreenshot}
                  </Button>
                )}
              </div>
              <div className={styles.cardActions}>
                <Actions
                  {...{
                    getPopupContainer,
                    isActive,
                    projectId: id,
                    canDelete: deleteProject,
                    canDisable: disableProject,
                    handleReadAll,
                    setShowRefresh
                  }}
                />
              </div>
            </div>
          )}
        </Card>
      </a>
      {viewKanban ? (
        <a href={projectURL} className={styles.viewLink}>
          {isDevUrlPresent && <LinkIcon className={styles.viewIcon} />}
          {getViewCardText()}
        </a>
      ) : (
        <a href={projectURL} className={styles.viewLink}>
          <MouseArrow className={styles.viewIcon} /> {strings.giveFeedback}
        </a>
      )}
    </div>
  );
};

export default ProjectCard;
