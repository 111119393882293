import * as React from 'react';
import { Button } from 'antd';
import * as translations from './strings';
import { migratePlanUrl, reactivateUrl, subscribeUrl } from '../routes';
import { getRecommendedMax } from '../additionalMembers';

const strings: translations.Translations = translations.en;

const SubscribeButton: React.FC<{
  plan: {
    id: string;
    maxMembers: number;
    name: string;
    handle: string;
    interval: string;
  };
  organization: {
    id: string;
    numMembers: number;
    validPartnerCoupon: boolean;
  };
  subscription:
    | {
        __typename: 'SubscriptionPlan';
        maxMembers: number;
        state: string;
        interval: string;
        plan: { handle: string; maxMembers: number };
      }
    | { __typename: 'SubscriptionTrial' };
}> = ({ plan, organization, subscription }) => {
  const additionalMembers: number = (() => {
    const samePlanDifferentInterval =
      subscription.__typename == 'SubscriptionPlan' &&
      plan.maxMembers === subscription.plan.maxMembers;

    const diff =
      samePlanDifferentInterval && subscription.__typename == 'SubscriptionPlan'
        ? subscription.maxMembers - plan.maxMembers
        : organization.numMembers - plan.maxMembers;

    return diff > 0 ? diff : 0;
  })();

  const migratePlanLink = migratePlanUrl({
    organizationId: organization.id,
    planId: plan.id,
    additionalMembers: additionalMembers
  });

  const reactivateLink = reactivateUrl({
    organizationId: organization.id,
    planId: plan.id
  });

  const subscribeLink = subscribeUrl({
    organizationId: organization.id,
    planId: plan.id,
    additionalMembers: additionalMembers
  });

  const recommendedMax = getRecommendedMax(plan.name);
  if (
    plan.maxMembers < organization.numMembers &&
    !!recommendedMax &&
    additionalMembers > recommendedMax
  ) {
    return (
      <Button type="primary" disabled>
        {strings.usageExceeded}
      </Button>
    );
  }
  if (subscription.__typename === 'SubscriptionTrial') {
    return (
      <Button type="primary" href={subscribeLink}>
        {strings.subscribe}
      </Button>
    );
  }
  const { state } = subscription;
  if (subscription.plan.handle === plan.handle) {
    return (
      <div>
        <Button disabled>{strings.currentPlan}</Button>
        {organization.validPartnerCoupon && <em>{strings.discountApplied}</em>}
      </div>
    );
  }

  if (['canceled'].includes(state)) {
    return (
      <Button type="primary" href={reactivateLink}>
        {strings.reactivate}
      </Button>
    );
  } else if (state === 'cancelling') {
    return (
      <Button type="primary" href={migratePlanLink}>
        {strings.reactivate}
      </Button>
    );
  } else if (state === 'paused') {
    return (
      <Button type="primary" href={migratePlanLink}>
        {strings.resume}
      </Button>
    );
  } else if (subscription.interval !== plan.interval) {
    return (
      <Button type="primary" href={migratePlanLink}>
        {strings.switch}
      </Button>
    );
  } else if (
    plan.maxMembers != null &&
    subscription.maxMembers < plan.maxMembers
  ) {
    return (
      <Button type="primary" href={migratePlanLink}>
        {strings.upgrade}
      </Button>
    );
  } else if (
    plan.maxMembers != null &&
    subscription.maxMembers > plan.maxMembers
  ) {
    return (
      <Button type="primary" href={migratePlanLink}>
        {strings.downgrade}
      </Button>
    );
  } else {
    return (
      <Button type="primary" href={subscribeLink}>
        {strings.subscribe}
      </Button>
    );
  }
};

export default SubscribeButton;
