import { updateTaskWithScreenshotError } from '../api/TaskUpdateApi';
import { requestScreenshot as requestScreenshotFromServer } from '../screenshotServer';
import { uploadScreenshot } from '../screenshotUpload';
import PropTypes from 'prop-types';

import {
  CAPTURE_ERROR,
  SCREENSHOT_NOT_SUPPORTED
} from '../models/ScreenshotCaptureError';

export const handleScreenshot = ({
  isScreenshotServerAccess,
  taskId,
  s3Response,
  polyfillData,
  dataURI,
  dataRecording,
  captureError,
  uploadState,
  screenshotServerUrl,
  projectId,
  bugherdUrl,
  rescueScreenshotsEnabled,
  organizationId,
  userId
}) => {
  if (dataURI || dataRecording) {
    return uploadScreenshot({
      uploadMetaData: s3Response,
      dataURI,
      dataRecording,
      taskId,
      upload: uploadState
    });
  }

  if (polyfillData && isScreenshotServerAccess) {
    return requestScreenshotFromServer({
      taskId,
      s3Response,
      polyfillData,
      screenshotServerUrl,
      projectId,
      bugherdUrl,
      rescueScreenshotsEnabled,
      organizationId,
      userId
    });
  }

  if (
    !isScreenshotServerAccess &&
    !dataURI &&
    captureError === SCREENSHOT_NOT_SUPPORTED
  ) {
    return updateTaskWithScreenshotError(
      taskId,
      bugherdUrl,
      projectId,
      polyfillData
    );
  }
  if (captureError) {
    return updateTaskWithScreenshotError(
      taskId,
      captureError,
      bugherdUrl,
      projectId,
      polyfillData
    );
  } else {
    return updateTaskWithScreenshotError(
      taskId,
      CAPTURE_ERROR,
      bugherdUrl,
      projectId,
      polyfillData
    );
  }
};

handleScreenshot.propTypes = {
  isScreenshotServerAccess: PropTypes.bool.isRequired,
  taskId: PropTypes.number.isRequired,
  s3Response: PropTypes.object.isRequired,
  polyfillData: PropTypes.object,
  dataURI: PropTypes.object,
  dataRecording: PropTypes.object,
  captureError: PropTypes.string,
  uploadState: {
    onProgress: PropTypes.func,
    onStart: PropTypes.func
  }
};
